import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.appendTimeZoneToURL()
  }

  appendTimeZoneToURL () {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    console.log(userTimeZone)

    if (!userTimeZone) return

    const url = new URL(window.location.href)

    if (url.searchParams.has('time_zone')) return

    url.searchParams.set('time_zone', userTimeZone)

    window.location.replace(url.toString())
  }
}
